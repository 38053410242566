import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../style/main.scss';
import '../../style/sectionsStyle/section5.scss';

import FirstPackage from '../../style/imgs/packages/firstPackage.png';
import SecondPackage from '../../style/imgs/packages/secondPackage.png';
import ThirdPackage from '../../style/imgs/packages/thirdPackage.png';
import FourthPackage from '../../style/imgs/packages/forthPackage.png';

function Section5(props) {
  const btnRef = React.useRef(null);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null); // Add this line

  const navigate = useNavigate();

  const leftClick = () => {
    btnRef.current.style.left = '0px';
    btnRef.current.style.width = '105px';
    btnRef.current.style.transform = 'translateX(0)';

  };

  const rightClick = () => {
    btnRef.current.style.left = '120px';
    btnRef.current.style.width = '125px';
    btnRef.current.style.transform = 'translateX(-20px)';
  };

  const [selectedOption, setSelectedOption] = useState(0);

  const toggleDisplayMode = () => {
    setSelectedOption((prevOption) => (prevOption === 0 ? 1 : 0));
  };
 
  return (
    <section id="section5" className="section5">
      <div className="switchPackages" onClick={toggleDisplayMode}>
        <span id="btn" ref={btnRef}></span>
        <button onClick={leftClick} className={`${selectedOption === 1 ? 'selected' : ''}`}>
          ДВОИХ
        </button>
        <button onClick={rightClick} className={`switchOption ${selectedOption === 0 ? 'selected' : ''}`}>
          КОМПАНИЙ
        </button>
      </div>


      <div className="card coupleCards" style={{ display: selectedOption === 0 ? 'flex' : 'none' }}>
        <div className="card CoupleCard">
          <h3 className="fc">РОМАНТИК</h3>
          <img src={FirstPackage} alt="романтический пакет" />
          <div className="order">
            <div className="price">
              <h4>5000 ₽</h4>
            </div>
            <a             href='https://wa.me/+79200242926'

            // onClick={() => handleLinkClick('РОМАНТИК')} value="РОМАНТИК" 
              className="buy-button">
              <h4  className="orderBtn">ЗАКАЗАТЬ</h4>
            </a>
          </div>
        </div>


        <div className="card CoupleCard">
          <h3 className="sc">БЕЗУМНЫЙ ВЕЧЕР</h3>
          <img src={SecondPackage} alt="романтический пакет" />
          <div className="order">
            <div className=" prices price">
              <h4>8000 ₽</h4>
            </div>

            <a  href='https://wa.me/+79200242926'

           // onClick={() => handleLinkClick('БЕЗУМНЫЙ ВЕЧЕР')} value="БЕЗУМНЫЙ ВЕЧЕР"
             className="buy-button">
              <h4 className="orderBtn" >ЗАКАЗАТЬ</h4>
            </a>
          </div>
        </div>

        <div className="card CoupleCard">
          <h3 className="tc">НА ЗВЕЗДАХ</h3>
          <img src={ThirdPackage} alt="романтический пакет" />
          <div className="order">
            <div className=" price">
              <h4>14000 ₽</h4>
            </div>
            <a 
            href='https://wa.me/+79200242926'
          //  onClick={() => handleLinkClick('НА ЗВЕЗДАХ')}
             value="НА ЗВЕЗДАХ"  className="buy-button">
              <h4 className="orderBtn">ЗАКАЗАТЬ</h4>
            </a>
          </div>
        </div>
      </div>

      <div className="card groupCard " style={{ display: selectedOption === 1 ? 'block' : 'none' }}>
        <h3>ДЛЯ КОМПАНИЙ</h3>
        <img src={FourthPackage} alt="групповой пакет" />
        <div className="order">
          <div className="price">
            <h4>от 2000</h4>
          </div>
          <a href='https://wa.me/+79200242926' 
          //onClick={() => handleLinkClick('КОМПАНИЙ')} 
          value="КОМПАНИЙ"  className="buy-button">
            
              <h4 className="orderBtn">ЗАКАЗАТЬ</h4>
            </a>
        </div>
      </div>
    </section>
  );
}

export default Section5;