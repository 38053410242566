import React from 'react'

//style
import "../../style/main.scss"
import "../../style/sectionsStyle/section1.scss"

//components 
import Nav from '../nav'

function section1() {
  return (
    
           <section id='section1'className='section1'>
           <Nav/>
   <div className="main">
   <h1>КИНОТЕАТР ДЛЯ ДВОИХ ИЛИ ХАЙПОВОЕ МЕСТО ДЛЯ КОМПАНИЙ</h1>
   <p>SOVACINEMA - это лофт-пространство, где каждый сможет чудесно отдохнуть!
Мы организуем идеальное свидание в частном кинотеатре для вас и вашей половинки или крутую вечеринку для большой компании!
Кинотеатр с любыми фильмами, караоке, танцпол, возможность шуметь круглые сутки - это всё о SOVACINEMA.</p>
   <a href="https://wa.me/+79200242926"><button >Заказать</button></a>

   </div>
   </section>
  )
}

export default section1